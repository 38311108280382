/**
 * Composable for fetching box types.
 */
export default function useBoxTypes() {
  const { listBoxTypes } = useBoxTypeApi();
  const {
    data: boxTypes,
    isLoading: isLoadingBoxTypes,
    isError: isErrorBoxTypes,
  } = listBoxTypes();
  return {
    boxTypes,
    isErrorBoxTypes,
    isLoadingBoxTypes,
  };
}
